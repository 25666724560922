.bg-primary {
  background-color: $brand-primary;
  color: $white;
}
.bg-secondary {
  background-color: $brand-secondary;
  color: $white;
}
.bg-green {
  background-color: $green;
  color: $brand-primary;
}
.bg-teal-light {
  background-color: $teal-light;
}
.bg-gray-light {
  background-color: $gray-light;
}

.condensed-header {
  font-weight: $headings-font-weight;
  font-family: $headings-font-family;
  line-height: $headings-line-height;
  text-transform: lowercase;
//   text-transform: uppercase;
}
.list-normalize {
  margin: 0;
  padding: 0;
  list-style: none;
}
.title-border {
  border-bottom: 3px solid $brand-secondary;
}
