ul.impacts {
  @extend .list-normalize;
  li {
    display: block;
    a {
      display: block;
      width: 100%;
      border: 2px solid $white;
      box-shadow: none;
      &.btn-default {
        border-left: none;
        border-right: none;
      }
      @include hover-focus {
        border-color: $white;
      }
    }
  }
}
.page-hero {
  position: relative;
  .property-image {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @include media-breakpoint-up(sm) {
      height: 300px;
    }
    @include media-breakpoint-up(md) {
      height: 360px;
    }
    @include media-breakpoint-up(lg) {
      height: 450px;
    }
    @include media-breakpoint-up(xl) {
      height: 550px;
    }
  }
  img {
    width: 100%;
  }
  .welcome {
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    h1 {
      @extend .condensed-header;
      text-transform: none;
      letter-spacing: -0.02em;
      line-height: .9;
      color: $white;
      text-shadow: 0px 5px 12px rgba($black, 0.4);
      margin: 0 0 .25em;
      small {
        font-size: 45%;
        display: block;
        margin-bottom: .25em;
      }
      @include media-breakpoint-up(md) {
        font-size: 5.5rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: $font-size-hero;
      }
    }
    ul.impacts {
      display: inline-block;
      margin: $grid-gutter-width auto 0;
      li {
        display: inline;
        float: left;
        a {
          width: 200px;
        }
      }
    }
  }

}
#videoModal {
  .modal-dialog {
    @include media-breakpoint-up(lg) {
      max-width: 700px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 900px;
    }
    .modal-content {
      border: none;
      background-color: rgba($brand-primary,.8);
      .modal-header {
        border: none;
        padding-bottom: 0;
        .close {
          color: $white;
        }
      }
    }
  }
}
#searchModal {
  .modal-dialog {
    max-width: 380px !important;
    .modal-content {
      background-color: rgba($gray-light,.9);
/*
      .modal-header {
        border: none;
        padding-bottom: 0;
        .close {
          color: $white;
        }
      }
*/
    }
  }
}
