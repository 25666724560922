.btn {
  @extend .condensed-header;
  text-transform: none !important;
  cursor: pointer;
  border: none;
  border-radius: 0;
  font-size: $btn-font-size;
  box-shadow: $btn-box-shadow;
  color: $btn-default-color;
  background-color: $btn-default-bg;
  line-height: $btn-line-height;
  overflow: hidden;
//   padding-bottom: $btn-padding-y*.5;
  @include hover-focus {
    color: $btn-default-color-hover;
    background-color: $btn-default-bg-hover;
    box-shadow: $btn-focus-box-shadow;
  }
  &.btn-primary {
    color: $btn-primary-color;
    background-color: $btn-primary-bg;
    @include hover-focus {
      color: $btn-primary-color-hover;
      background-color: $btn-primary-bg-hover;
    }
  }
  &.btn-secondary {
    color: $btn-secondary-color;
    background-color: $btn-secondary-bg;
    @include hover-focus {
      color: $btn-secondary-color-hover;
      background-color: $btn-secondary-bg-hover;
    }
  }
  &.btn-white {
    color: $brand-primary;
    background-color: $white;
    @include hover-focus {
      color: $brand-primary;
      background-color: $orange;
    }
  }

  &.btn-sm {
    font-size: $font-size-3xs;
//     padding-bottom: $btn-padding-y-sm*.5;
  }
  &.btn-lg {
    font-size: $font-size-xl;
//     padding-bottom: $btn-padding-y-lg*.5;
  }
}

