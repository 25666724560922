footer.content-info {
  @extend .bg-secondary;
  padding: $grid-gutter-width*2 0;
  font-size: $font-size-sm;
  line-height: 1.3;
  h3 {
    font-size: $font-size-h4;
    color: $white;
    text-transform: none;
    margin-bottom: $grid-gutter-height;
  }
  a {
    color: $white;
    text-decoration: none;
    @include hover-focus {
      color: $orange;
    }
  }
  .about-col,.nav-col,.news-col {
    @include media-breakpoint-down(md) {
      margin-bottom: $grid-gutter-width*2;
    }
  }
  .nav-col {
    nav {
      ul {
        @extend .list-normalize;
        margin-bottom: 1em;
        margin-left: 0;
        padding-left: 0;
        li {
          padding-left: 1em;
          text-indent: -1em;
          margin-bottom: .4em;
          line-height: 1.2;
          &:before {
            content: ">";
            padding-right: .425em;
          }
          a {
            color: $white;
            text-decoration: none;
            @include hover-focus {
              color: $green;
            }
          }
        }
      }
    }
  }
  .news-col {
    @include media-breakpoint-up(lg) {
      padding-left: $grid-gutter-width;
    }
    @include media-breakpoint-up(xl) {
      padding-left: $grid-gutter-width*2;
    }
    section {
      article {
        margin-left: 0;
        margin-right: 0;
        padding-top: $grid-gutter-height;
        padding-bottom: $grid-gutter-height;
        border-top: 1px solid $brand-primary;
        &:first-child {
          padding-top: 0;
          border-top: none;
        }
        .news-date {
          max-width: 70px;
          padding-left: 0;
          time {
            @extend .bg-primary;
//             @extend .condensed-header;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: $font-size-h3-lg;
            text-transform: uppercase;
            width: 60px;
            height: 60px;
            line-height: .85;
            padding-top: 5px;
            span {
              display: block;
//               color: $green;
              font-size: $font-size-sm;
              font-weight: $font-weight-bold;
            }
          }
        }
        .news-details {
          padding-right: 0;
          font-size: $font-size-2xs;
          h4 {
            text-transform: none;
            font-size: $font-size-base;
            margin-bottom: .5em;
            a {
              text-decoration: none;
              color: $white;
              @include hover-focus {
                color: $green;
              }
            }
          }
        }
      }
    }
  }
}
.copyright {
  @extend .bg-primary;
  margin: 0;
  text-align: center;
  padding: $grid-gutter-width*.5;
  font-size: $font-size-2xs;
  line-height: 1.2;
}
