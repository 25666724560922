aside.sidebar {
  .widget {
    margin-bottom: $grid-gutter-width*2;
    h3 {
      @extend .title-border;
      font-size: $font-size-h4-lg;
      margin: 0 0 $grid-gutter-width;
      padding: 0 0 $grid-gutter-width*.6;
    }

    &.widget_nav_menu {
      ul {
        @extend .list-normalize;
        margin-bottom: 1em;
        margin-left: 0;
        padding-left: 0;
        font-size: $font-size-xs;
        li {
          padding-left: 1.3em;
          text-indent: -1.3em;
          margin: $grid-gutter-width 0;
          line-height: 1.2;
          &:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f0da";
            padding-right: 1em;
            color: $brand-secondary;
          }
          a {
            color: $brand-primary;
            text-decoration: none;
            @include hover-focus {
              color: $brand-secondary;
            }
          }
          &.current-post-parent {
            &:before {
              color: $green;
            }
          }
        }
      }
    }


    nav.socials {
      ul {
        @extend .list-normalize;
        li {
          display: inline-block;
          margin: 0 $grid-gutter-width*.3;
          a {
            display: block;
            width: $grid-gutter-width*2;
            height: $grid-gutter-width*2;
            border-radius: 50%;
            background-color: $brand-primary;
            text-align: center;
            line-height: $grid-gutter-width*2;
            @include hover-focus {
              background-color: $brand-secondary;
            }
            i {
              color: $white;
              font-size: $font-size-lg;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
