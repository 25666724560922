// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(lg) {
    @include make-col($main-lg-columns);
    .sidebar-primary & {
      @include make-col($main-lg-columns - $sidebar-lg-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(lg) {
    @include make-col($sidebar-lg-columns);
  }
}
