.table-property {
  @extend .table-striped;
  font-size: $font-size-2xs;
  @include media-breakpoint-up(md) {
    font-size: $font-size-xs;
  }
  @include media-breakpoint-up(lg) {
    font-size: $font-size-base;
  }
  tr {
    th {
      font-family: $font-family-sans-alt;
      color: $brand-primary;
    }
  }
}
