html {
  font-size: 18px;
  scroll-behavior: smooth;
}
body {
  line-height: $line-base;
}
a {
  @include transition($link-transition);
}
p,ol,ul,dl,address,pre,blockquote,.slideshow-gallery {
  margin-bottom: 1.5rem;
}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  text-transform: none;
  margin-bottom: 1rem;
  color: $brand-primary;
}

img {
  @extend .img-fluid;
}
.container {
  @include media-breakpoint-down(sm) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}
hr.thick {
  border-top: 3px solid $brand-secondary;
  margin: $grid-gutter-width*2.5 0 0;
}


main.main {
  h3,.h3,h4,.h4,h5,.h5 {
    margin-top: $grid-gutter-width*1.5;
  }
}
.more-properties {
  margin-top: $grid-gutter-width;
  text-align: center;
  .btn {
    margin-bottom: $grid-gutter-height;
  }
  @include media-breakpoint-down(sm) {
    .btn {
      display: block;
      width: 100%;
    }
  }
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  iframe,object,embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
