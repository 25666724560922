.grid-items {
  .grid-item {
    margin-bottom: $grid-gutter-width;
    article.hentry {
      @extend .bg-gray-light;
      height: 100%;
      figure {
        @extend .bg-primary;
        border-bottom: 14px solid $brand-secondary;
        margin: 0;
        a {
          position: relative;
        }
        img.video-btn {
          position: absolute;
          width: 35%;
          height: auto;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          opacity: .85;
        }
        a:hover {
          img {
            @include transition($link-transition);
            opacity: .9;
            &.video-btn {
              opacity: 1;
            }
          }
        }
        a.property-image {
          width: 100%;
          height: 250px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          display: block;
        }
      }
      .entry-summary {
        padding: $grid-gutter-height $grid-gutter-width 0;
        font-size: $font-size-sm;
        line-height: 1.3;
        .entry-meta {
          font-size: $font-size-3xs;
          font-weight: $font-weight-bold;
          color: $brand-secondary;
          margin-bottom: $grid-gutter-height;
        }
        h4 {
          margin-bottom: $grid-gutter-height*1.25;
          a {
            color: $brand-primary;
            text-decoration: none;
            @include hover-focus {
              color: $brand-secondary;
            }
          }
        }
        p {
          margin-bottom: 0;
        }
      }
      .readmore {
        @extend .condensed-header;
        font-size: $font-size-xs;
        display: block;
        padding:  $grid-gutter-height $grid-gutter-width;
        text-align: right;
        text-decoration: none;
        i {
          @include transition($link-transition);
          color: $brand-primary;
          margin-left: $grid-gutter-width*.25;
        }
        @include hover-focus {
          color: $body-color;
          i {
            color: $brand-secondary;
          }
        }
      }
    }
    &.property-item {
      article.hentry {
        h4 {
          margin-top: 0;
        }
        .readmore {
          text-align: left;
        }
      }
    }
  }
  &.related-posts-grid {
    margin-top: $grid-gutter-width*3;
    h3.related-posts-title {
      @extend .title-border;
      font-size: $font-size-h3-lg;
      padding-bottom: $grid-gutter-height*.75;
      margin-bottom: $grid-gutter-width*1.5;
    }
  }
  .readall {
    text-align: center;
    margin-top: $grid-gutter-width;
  }
}
.category-navigation {
  .nav {
    @extend .condensed-header;
    font-size: $font-size-lg;
    margin-bottom: $grid-gutter-width*2;
    .nav-item {
      .nav-link {
        color: $body-color;
        padding: $grid-gutter-width*.25 $grid-gutter-width*.75;
        @include hover-focus {
          color: $brand-secondary;
        }
      }
      &.active {
        .nav-link {
          color: $brand-secondary;
        }
      }
    }
  }
}
.category-navigation-mobile {
  @extend .condensed-header;
  border-top: 1px solid $brand-secondary;
  border-bottom: 1px solid $brand-secondary;
  margin-bottom: $grid-gutter-width*1.75;
  .toggler {
    font-size: $font-size-lg;
    display: block;
    padding: $grid-gutter-height*.75 0 $grid-gutter-height*.5;
    text-decoration: none;
    i {
      float: right;
      margin-top: -2px;
    }
  }
  ul {
    @extend .list-normalize;
    li {
      margin-bottom: 10px;
      a {
        color: $body-color;
        padding: $grid-gutter-width*.1 0;
      }
      &.active {
        a {
          color: $brand-secondary;
        }
      }

    }
  }
}
.posts-navigation {
  @extend .condensed-header;
  font-size: $font-size-sm;
  margin: $grid-gutter-width 0;
  .nav-links {
    @extend .row;
    .nav-previous,.nav-next {
      @extend .col;
      a {
        color: $brand-primary;
        text-decoration: none;
        @include hover-focus {
          color: $brand-secondary;
        }
      }
    }
    .nav-previous {
      a {
        i {
          margin-right: $grid-gutter-width*.5;
        }
      }
    }
    .nav-next {
      text-align: right;
      a {
        i {
          margin-left: $grid-gutter-width*.5;
        }
      }
    }
  }
}
.pagination-nav {
  @extend .condensed-header;
  font-size: $font-size-sm;
  margin: $grid-gutter-width 0;
  text-align: center;
  span.current {
    margin: 0 5px;
    color: $brand-secondary;
  }
  a {
    color: $body-color;
    text-decoration: none;
    margin: 0 5px;
    @include hover-focus {
      color: $brand-secondary;
    }
    &.prev,&.next {
      color: $brand-primary;
      @include hover-focus {
        color: $brand-secondary;
      }
    }
    &.prev {
      margin-right: $grid-gutter-width;
      i {
        margin-right: $grid-gutter-width*.5;
      }
      @include media-breakpoint-up(md) {
        float: left;
      }
    }
    &.next {
      margin-left: $grid-gutter-width;
      i {
        margin-left: $grid-gutter-width*.5;
      }
      @include media-breakpoint-up(md) {
        float: right;
      }
    }

  }
}
.single-post {
  .entry-meta {
    font-size: $font-size-2xs;
    font-weight: $font-weight-bold;
    color: $brand-secondary;
    margin-bottom: $grid-gutter-height;
  }
}
