// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
  height: $input-height;
}
.form-control {
  height: $input-height;
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
}
textarea.form-control {
  height: auto;
}
select.form-control {
  -webkit-appearance: none;
  background: $white url(../images/select-arrow.png) no-repeat right center;
  background-size: auto 12px;
  font: $font-size-base $font-family-base;
  height: $input-height !important;
  padding-right: $input-padding-x*2;
}


.gform_wrapper {
  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),textarea,select {
    @extend .form-control;
  }
  select {
    padding: $input-padding-y $input-padding-x !important;
    height: $input-height !important;
    padding-right: $input-padding-x*2 !important;
  }


  ul.gform_fields {
    li.gfield {
      padding-right: 0;
      margin-top: $grid-gutter-width*1.5;
      vertical-align: top;
      @media (max-width: 640px) {
        #input_1_1_6_container {
          padding-top: 22px;
        }
      }
      @media (min-width: 641px) {
        &#field_1_2 {
          width: 50%;
          display: inline-block;
          padding-right: 8px;
        }
         &#field_1_3 {
          width: 50%;
          display: inline-block;
          padding-left: 9px;
          padding-right: 0;
        }
      }
      span.name_first,span.name_last,span.name_middle {
        padding-top: 0;
      }
      &.hidden_label {
        input {
          margin-top: 0;
        }
      }
    }
    div.ginput_container {
      margin-top: 0;
    }
  }
  .field_sublabel_hidden_label {
    .ginput_complex.ginput_container {
      input[type=text],select {
        margin-bottom: 0;
      }
    }
  }
  .ginput_recaptcha {
    &>div {
      margin: $grid-gutter-width*2 auto 0;
    }
  }
  .gform_footer, .gform_page_footer {
    text-align: center;
    input.button,input[type=submit] {
      @extend .btn;
    }
  }
  &.gform_validation_error {
    div.validation_error {
      background-color: $red;
      color: $white;
      border: none;
    }
    .gform_body {
      ul {
        li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
          max-width: 100% !important;
        }
        li.gfield.gfield_error,
        li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
          background-color: transparent;
          border-top: none;
          border-bottom: none;
          margin-bottom: 0 !important;
          padding-top: 0;
          padding-bottom: 0;
          .ginput_recaptcha {
            &+.validation_message {
              padding-right: 0;
              text-align: center;
            }
          }
        }
        li.gfield.gfield_error.gfield_contains_required div.ginput_container,
        li.gfield.gfield_error.gfield_contains_required label.gfield_label {
          margin-top: 0;
        }
        li.gfield_error {
          input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
          textarea,select {
            border: 1px solid $red;
          }
        }
        .field_description_below .gfield_description {
          padding-top: 2px;
        }
        .validation_message {
          color: $red;
          font: $font-weight-base $font-size-2xs $font-family-sans-alt;
        }
      }
    }
  }
}
