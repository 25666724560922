// Grid settings
$main-lg-columns:         12;
$sidebar-lg-columns:      4;
$grid-gutter-width-base:  20px;
$grid-gutter-width:       $grid-gutter-width-base;
$grid-gutter-height:      16px;

// Colors
// $blue:            #2A3247;
// $teal:            #00666E;
// $green:           #D1D90E;
$blue:            #112A50;
$teal:            #2E5E65;
$teal-light:      #e5f0f0;
$orange:          #E47C18;
$green:           $orange;
$black:           #000;
$white:           #fff;
$gray:            #C4C4C4;
$gray-light:      #f3f3f3;
// $gray-dark:       #575757;
$gray-dark:       #58585A;

$gray-darker:     #383838;
$red:             #c00;

$brand-primary:   $blue;
$brand-secondary: $teal;

$body-bg:         $white;
$body-color:      $gray-dark;

$link-color:            $brand-secondary;
$link-decoration:       none;
$link-hover-color:      darken($link-color, 5%);
$link-hover-decoration: underline;
$link-transition:       all .15s ease-in-out;
$link-transition-slow:  all 1s ease-in-out;

// Fonts
/*
$font-family-serif:      'EgyptienneFLTStd', Georgia, "Times New Roman", Times, serif;
$font-family-sans-serif: 'DINCondensed-Regular', "Impact", "Arial Black", sans-serif;
$font-family-sans-alt:   'Roboto', "Segoe UI", "Helvetica Neue", Arial, sans-serif;
*/
$font-family-serif:      'Sanchez', Georgia, "Times New Roman", Times, serif;
$font-family-sans-serif: 'Manjari', "Helvetica Neue", Arial, sans-serif;
$font-family-sans-alt:   $font-family-sans-serif;
$font-family-sans:       $font-family-sans-serif;
$font-family-base:       $font-family-sans;

$font-weight-normal:  400;
$font-weight-base:    $font-weight-normal;
$font-weight-med:     600;
$font-weight-bold:    700;

$font-size-base: 1rem;         // 18px
$font-size-lg:   1.333333rem;  // 24px
$font-size-xl:   1.666666rem;  // 30px
$font-size-md:   .9444444rem;  // 17px
$font-size-sm:   .8888888rem;  // 16px
$font-size-xs:   .8333333rem;  // 15px
$font-size-2xs:  .7777777rem;  // 14px
$font-size-3xs:  .6666666rem;  // 12px

$font-size-h1:   2.777777rem;  // 60px
$font-size-h2:   2.3rem;       // 41px
$font-size-h3-lg:2rem;         // 36px
$font-size-h3:   1.666666rem;  // 30px
$font-size-h4-lg:1.444444rem;  // 26px
$font-size-h4:   1.333333rem;  // 24px
$font-size-h5:   1.111111rem;  // 20px
$font-size-h6:   1rem;         // 18px

$font-size-hero: 7rem;      // 130px
$font-size-huge: 9rem;         // 162px

$line-base:            1.65;

$headings-font-family:   $font-family-serif;
$headings-font-weight:   $font-weight-base;
$headings-line-height:   1.2;
$headings-color:         inherit;

// Buttons
$btn-padding-x:                  1.5rem;
$btn-padding-y:                  .65rem;
$btn-line-height:                1;
$btn-font-weight:                $font-weight-base;
$btn-font-size:                  $font-size-base;
$btn-box-shadow:                 0px 4px 4px rgba($black, 0.25);
$btn-focus-box-shadow:           0px 4px 4px rgba($black, 0.45);
$btn-active-box-shadow:          $btn-focus-box-shadow;

$btn-default-color:              $white;
$btn-default-bg:                 $green;
$btn-default-color-hover:        $white;
$btn-default-bg-hover:           $brand-primary;

$btn-primary-color:              $white;
$btn-primary-bg:                 $brand-primary;
$btn-primary-color-hover:        $brand-primary;
$btn-primary-bg-hover:           $green;

$btn-secondary-color:            $white;
$btn-secondary-bg:               $brand-secondary;
$btn-secondary-color-hover:      $white;
$btn-secondary-bg-hover:         $brand-primary;

$btn-padding-x-sm:               .8rem;
$btn-padding-y-sm:               .45rem;

$btn-padding-x-lg:               1.5rem;
$btn-padding-y-lg:               .8rem;

// Forms

$input-padding-x:                1rem;
$input-padding-y:                .85rem;
$input-line-height:              1;
$input-height:                   58px;

$input-bg:                       $white;
$input-bg-disabled:              $gray;

$input-color:                    $body-color;
$input-border-color:             $brand-secondary;
$input-btn-border-width:         2px;
$input-box-shadow:               none;

$input-border-radius:            0;
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;

$input-bg-focus:                 $gray-light;
$input-border-focus:             $brand-primary;
$input-box-shadow-focus:         none;
$input-color-focus:              $input-color;

$input-color-placeholder:        rgba($body-color,.6);

$input-transition:               border-color ease-in-out .15s;

