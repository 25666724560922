.wrap {
  margin-top: $grid-gutter-width*2;
  margin-bottom: $grid-gutter-width*2;
}
.sidebar-primary {
  aside.sidebar {
    margin-top: $grid-gutter-width*2;
  }
  @include media-breakpoint-up(lg) {
    main.main {
      padding-right: $grid-gutter-width*1.75;
    }
    aside.sidebar {
      border-left: 1px solid $gray;
      padding-left: $grid-gutter-width*1.75;
    }
  }
  @include media-breakpoint-up(xl) {
    main.main {
      padding-right: $grid-gutter-width*2.25;
    }
    aside.sidebar {
      padding-left: $grid-gutter-width*2.25;
    }
  }
}
section.introduction {
  @extend .bg-primary;
  text-align: center;
//   padding: $grid-gutter-width*2 0;
  padding: $grid-gutter-width 0 $grid-gutter-width*1.5;
  @include media-breakpoint-up(md) {
//     padding: $grid-gutter-width*2.5 0;
    padding: $grid-gutter-width*1.5 0 $grid-gutter-width*2;
  }
  @include media-breakpoint-up(lg) {
//     padding: $grid-gutter-width*3 0;
    padding: $grid-gutter-width*2 0 $grid-gutter-width*2.5;
  }
  h1.page-title {
    color: $green;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.page-header {
  #breadcrumbs {
    font: $font-weight-med $font-size-sm $font-family-sans-alt;
    margin-bottom: $grid-gutter-width*2;
    a {
      color: $body-color;
    }
    .breadcrumb_last {
      color: $brand-secondary;
    }
  }
  h1.entry-title {
    @extend .title-border;
    font-size: $font-size-h2;
    padding-bottom: $grid-gutter-height*.5;
    margin-bottom: $grid-gutter-width;
    small {
      margin-left: $grid-gutter-width/2;
      font: $font-weight-base $font-size-base $font-family-base;
      text-transform: none;
      line-height: 1.2;
      @include media-breakpoint-down(md) {
        display: block;
        margin: $grid-gutter-width/2 0 0;
      }
    }
  }
}
.content-wrap {
  h2 {
    padding-top: 1rem;
  }
  .wp-block-separator {
    margin: 2rem 0;
  }
  .page-template-template-landing & {
    @extend .col-md-10;
    @extend .offset-md-1;
    text-align: center;
  }
  .page-template-template-basic & {
/*
    @extend .col-md-10;
    @extend .offset-md-1;
*/
  }
}
.service-pages {
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width*2;
  text-align: center;
  justify-content: center;
  .service-page {
    margin-top: $grid-gutter-width*2;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    @include media-breakpoint-up(md) {
      padding-left: $grid-gutter-width*1.5;
      padding-right: $grid-gutter-width*1.5;
    }
    @include media-breakpoint-up(lg) {
      padding-left: $grid-gutter-width*1.75;
      padding-right: $grid-gutter-width*1.75;
    }
    img {
      display: block;
      margin: 0 auto $grid-gutter-width*1.5;
      max-width: 185px;
    }
  }
}

.page-template-template-services {
  aside.sidebar {
    margin-top: $grid-gutter-width*2;
  }
  @include media-breakpoint-up(lg) {
    main.main {
      padding-right: $grid-gutter-width*1.75;
    }
    aside.sidebar {
      border-left: 1px solid $gray;
      padding-left: $grid-gutter-width*1.75;
    }
  }
  @include media-breakpoint-up(xl) {
    main.main {
      padding-right: $grid-gutter-width*2.25;
    }
    aside.sidebar {
      padding-left: $grid-gutter-width*2.25;
    }
  }
}
.property-filter {
  @extend .bg-green;
  padding: $grid-gutter-width;
  text-align: center;
  h3 {
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-width/2;
    }
    @include media-breakpoint-up(lg) {
      display: inline-block;
      line-height: $grid-gutter-width*2;
      margin: 0;
      vertical-align: middle;
    }
  }
  select {
    width: 280px;
    padding: $grid-gutter-width/2 $grid-gutter-width*2 $grid-gutter-width/4 $grid-gutter-width/2;
    border-color: $brand-primary;
    border-width: 1px;
    color: $brand-primary;
    height: $grid-gutter-width*2 !important;
    margin: $grid-gutter-width auto 0;
    @include media-breakpoint-up(md) {
      width: 210px;
      display: inline-block;
      margin: 0 $grid-gutter-height/4;
      &:last-child {
        margin-right: 0
      }
    }
    @include media-breakpoint-up(lg) {
      width: 212px;
      margin: 0 $grid-gutter-height/3;
      &:last-child {
        margin-right: 0
      }
    }
    @include media-breakpoint-up(xl) {
      width: 230px;
      margin: 0 $grid-gutter-height/2;
      &:last-child {
        margin-right: 0
      }
    }
  }
}
