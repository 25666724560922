.cta-block {
  margin: $grid-gutter-width auto;
  max-width: 1200px;
  @include media-breakpoint-up(md) {
    margin: $grid-gutter-width*2 auto;
  }
  @include media-breakpoint-up(lg) {
    margin: $grid-gutter-width*3 auto;
  }
  .container {
    @include media-breakpoint-down(md) {
      padding: 0;
      .row {
        margin: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      width: 100%;
      .row {
        &.orient-right {
          flex-direction: row-reverse;
        }
      }
    }
  }
  .imagery,.detail {
    align-self: center;
  }
  .imagery {
    padding: 0;
    img,iframe {
      display: block;
      width: 100%;
    }
  }
  .detail {
    padding: 0;
    article {
      padding: $grid-gutter-width*2 $grid-gutter-width;
      @include media-breakpoint-up(md) {
        padding: $grid-gutter-width*3 $grid-gutter-width*2;
      }
      @include media-breakpoint-up(lg) {
        padding: $grid-gutter-width*3 $grid-gutter-width*3 $grid-gutter-width*3 $grid-gutter-width*3.5;
        margin-left: -$grid-gutter-width*2.75;
      }
      @include media-breakpoint-up(xl) {
        padding: $grid-gutter-width*4 $grid-gutter-width*4 $grid-gutter-width*4 $grid-gutter-width*4.5;
        margin-left: -$grid-gutter-width*3.5;
      }
      h3 {
        color: $white;
      }
      a.learn-more {
//         @extend .condensed-header;
        text-transform: lowercase;
        color: $green;
        display: block;
        text-decoration: none;
        @include hover-focus {
          color: $white;
        }
        .page-id-363 & {
          display: none;
        }
      }
    }
    .social {
      @extend .list-normalize;
      li {
        display: inline-block;
        margin: $grid-gutter-width $grid-gutter-height 0 0;
        a {
          display: block;
          width: $font-size-xl;
          height: $font-size-xl;
          border-radius: 50%;
          background-color: $white;
          text-align: center;
          @include hover-focus {
            background-color: $green
          }
          i {
            line-height: $font-size-xl;
            vertical-align: middle;
            color: $brand-primary;
            font-size: $font-size-sm;
          }
        }
      }
    }
  }
  .orient-right {
    .detail {
      article {
        @include media-breakpoint-up(lg) {
          padding: $grid-gutter-width*3 $grid-gutter-width*3.5 $grid-gutter-width*3 $grid-gutter-width*3;
          margin-right: -$grid-gutter-width*2.75;
          margin-left: 0
        }
        @include media-breakpoint-up(xl) {
          padding: $grid-gutter-width*4 $grid-gutter-width*4.5 $grid-gutter-width*4 $grid-gutter-width*4;
          margin-right: -$grid-gutter-width*3.5;
          margin-left: 0
        }
      }
    }
  }
}

.block {
  margin-bottom: $grid-gutter-width;
  &.block-connects {
    @extend .bg-teal-light;
    padding: $grid-gutter-width*2 0;
    text-align: center;
    font-size: $font-size-h3;
    color: $gray-darker;
    line-height: 1.45;
    @include media-breakpoint-up(lg) {
      font-size: $font-size-h3-lg;
    }
  }
  &.block-impact {
    @extend .bg-primary;
    padding: $grid-gutter-width*3 0;
    font-size: $font-size-h3;
    .icon {
      text-align: center;
      img {
        display: block;
        margin: 0 auto 0;
        max-width: 196px;
      }
    }
    .fact {
      padding-top: $grid-gutter-width*.75;
      @include media-breakpoint-down(sm) {
        text-align: center;
        padding-top: $grid-gutter-width*2.5;
      }
      line-height: 1.3;
      span {
        @extend .condensed-header;
        display: block;
        line-height: .8;
        letter-spacing: -.025em;
        color: $green;
        font-size: $font-size-huge;
      }
    }
  }
  &.block-partners {
    padding: $grid-gutter-width*2 0;
    text-align: center;
    line-height: 1.45;
    .logos {
      margin-bottom: $grid-gutter-width;
      img {
        margin: $grid-gutter-width;
        max-width: 200px;
        max-height: 150px;
      }
    }
  }
  &.block-properties {
    @extend .bg-teal-light;
    .properties-tiles {
      margin-left: 0;
      margin-right: 0;
      .slick-slide {
        padding: 0;
        position: relative;
        figure {
          @extend .bg-secondary;
          margin: 0;
          img {
            @include transition($link-transition);
            width: 100%;
          }
        }
        article {
          display: none;
          position: absolute;
          width: 80%;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          text-align: center;
          color: $white;
          overflow: hidden;
          h3 {
            color: $white;
            background: url(../images/underline-orange.png) no-repeat center bottom;
            background-size: auto 2px;
            padding-bottom: .75rem;
          }
          p {
            line-height: 1.5;
            @include media-breakpoint-only(sm) {
              display: none;
            }
          }
        }
        @include hover-focus {
          figure {
            img {
              mix-blend-mode: multiply;
            }
          }
          article {
            display: block;
          }
        }
      }
    }

    .properties-description {
      padding-top: $grid-gutter-width*2;
      padding-bottom: $grid-gutter-width*2;
      text-align: center;
      color: $gray-darker;
      line-height: 1.45;
    }
  }
  &.block-related {
    h2 {
      text-align: center;
    }
    .readall {
      text-align: center;
    }
  }
  &.block-image-tiles {
    .image-tiles {
      margin-left: 0;
      margin-right: 0;
      justify-content: center;
      .image-tile {
        padding: 0 0 6px;
        position: relative;
        @include media-breakpoint-up(md) {
          padding: 0 3px 6px;
        }
        figure {
          @extend .bg-secondary;
          margin: 0;
          img {
            @include transition($link-transition);
            width: 100%;
          }
        }
        article {
          @include transition($link-transition);
          display: none;
          position: absolute;
          width: 80%;
          left: 50%;
          top: $grid-gutter-width*2;
          transform: translateX(-50%);
          text-align: center;
          color: $white;
          overflow: hidden;
          h3 {
            color: $white;
            background: url(../images/underline-orange.png) no-repeat center bottom;
            background-size: auto 2px;
            padding-bottom: .75rem;
          }
          p {
            line-height: 1.5;
            @include media-breakpoint-only(sm) {
              display: none;
            }
          }
        }
        .tile-link {
          position: absolute;
          display: block;
          left: 0;
          bottom: 0;
          width: 100%;
          padding: 0 $grid-gutter-width*2.5 $grid-gutter-width*1.25;
          .btn {
            display: block;
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
          }
        }
        @include hover-focus {
          figure {
            img {
              opacity: .5;
              @supports(mix-blend-mode:multiply) {
                opacity: 1;
                mix-blend-mode: multiply;
              }
            }
          }
          article {
            display: block;
          }
        }
      }
    }
  }
  &.block-statistic {
    margin-top: $grid-gutter-width*1.5;
    margin-bottom: $grid-gutter-width*3;
    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-width*3.5;
    }
    .container {
      @include media-breakpoint-up(lg) {
        .row {
          &.orient-right {
            flex-direction: row-reverse;
          }
        }
      }
    }
    .imagery {
      text-align: center;
      img {
        margin-bottom: $grid-gutter-width*2;
      }
    }
    .imagery,.detail {
      align-self: center;
    }
    &+.block-statistic {
      .container {
        .row {
          padding-top: $grid-gutter-width*3;
          @include media-breakpoint-up(lg) {
            padding-top: $grid-gutter-width*3.5;
          }
          border-top: 3px solid $brand-secondary;
        }
      }
    }
  }
  &.block-team {
    display: block;
    font-size: 0;
    margin-bottom: $grid-gutter-width;
    .headshot {
      width: 100%;
      position: relative;
      @include media-breakpoint-up(sm) {
        width: 50%;
      }
      @include media-breakpoint-up(md) {
        width: 33.33%;
      }
      @include media-breakpoint-up(lg) {
        width: 25%;
      }
      display: inline-block;
      float: none;
      font-size: $font-size-base;
      padding: 0 $grid-gutter-width/2 $grid-gutter-width ;
      vertical-align: top;
      figure {
        @extend .bg-secondary;
        margin: 0;
        transition: all 350ms ease;
        overflow: hidden;
        img {
          @include transition($link-transition);
          width: 100%;
        }
      }

      article {
        @extend .bg-secondary;
        padding: 1rem;
        text-align: center;
        line-height: 1.2;
        .name {
          @extend .condensed-header;
          font-size: $font-size-h4;
          margin-bottom: $grid-gutter-width/2;
        }
        .job-title {
          margin-bottom: 0;
        }
      }
      @include hover-focus {
        cursor: pointer;
        figure {
          img {
            transform: scale(1.025);
/*
            @supports(mix-blend-mode:multiply) {
              opacity: 1;
              mix-blend-mode: multiply;
            }
*/
          }
        }
      }
      &.active {
        figure {
          img {
            transform: scale(1.025);
          }
        }
        &:after {
          @include transition($link-transition);
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 9px 9px 0 9px;
          border-color: $brand-secondary transparent transparent transparent;
          position: absolute;
          bottom: 12px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

    }
    .detail {
      display: block;
      float: left;
      width: 100%;
      overflow: hidden;
      font-size: $font-size-md;
      max-height: 0;
      transition: max-height 0.1s ease-out;
      opacity: 0;
      &.active {
        @include transition($link-transition-slow);
        display: block;
        max-height: 800px;
        transition: max-height 0.25s ease-out;
        padding: $grid-gutter-width/4 0 $grid-gutter-width*1.5;
        opacity: 1;
      }
      &>.container {
        position: relative;
        .team-close {
          @include transition($link-transition);
          z-index: 1;
          position: absolute;
          width: $grid-gutter-width*2;
          height: $grid-gutter-width*2;
          top: $grid-gutter-width;
          right: $grid-gutter-width/2;
          background: url(../images/team-close.png) no-repeat center center;
          background-size: $grid-gutter-width*2 auto;
          cursor: pointer;
          opacity: .7;
          @include hover-focus {
            opacity: 1;
          }
          @include media-breakpoint-up(md) {
            top: $grid-gutter-width/2;
            right: 0;
          }
        }
      }
      .bio {
        padding-top: $grid-gutter-width;
        padding-right: $grid-gutter-width*2.5;
        @include media-breakpoint-up(md) {
          max-height: 300px;
          overflow-y: scroll;
        }
        header {
          margin-bottom: $grid-gutter-width;
          padding-right: $grid-gutter-width*2.5;
          @include media-breakpoint-up(md) {
            padding-right: 0;
          }
          h3 {
            font-size: $font-size-h3-lg;
            color: $brand-secondary;
            margin: 0;
          }
          .job-title {
            font-size: $font-size-base;
            color: $brand-primary;
            font-weight: $font-weight-bold;
            margin: $grid-gutter-width/4 0 0;
            }
        }
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .bio::-webkit-scrollbar {
        -webkit-appearance: none;
      }
      .bio::-webkit-scrollbar:vertical {
        width: 4px;
      }
      .bio::-webkit-scrollbar-thumb {
        background-color: rgba($brand-primary,.8);
      }
      .contacts {
        padding-top: $grid-gutter-width*2;
        padding-bottom: $grid-gutter-width;
        @include media-breakpoint-up(md) {
          padding-top: $grid-gutter-width*4.5;
          padding-left: $grid-gutter-width*2;
        }
        p {
          margin: 0;
          padding: $grid-gutter-width*.4 0;
          border-top: 1px solid $gray-dark;
          strong {
            color: $brand-primary;
          }
          @include media-breakpoint-up(md) {
            &:first-child {
              border-top: none;
            }
          }
        }
      }
    }
  }

}
