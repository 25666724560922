header.banner {
  .logo {
    text-align: center;
    padding: $grid-gutter-width;
    margin: 0 auto 0 0;
    max-width: 280px;
    display: flex;
    flex: 1;
    align-items: center;
/*
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width*1.25 $grid-gutter-width;
    }
*/
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width*.85 $grid-gutter-width;
      max-width: 310px;
    }
    @include media-breakpoint-up(lg) {
//       padding: $grid-gutter-width*1.75 $grid-gutter-width;
      max-width: 262px;
    }
    @include media-breakpoint-up(xl) {
//       padding: $grid-gutter-width*1.5 $grid-gutter-width;
      max-width: 310px;
    }
    .brand {
      display: block;
      margin: 0 auto;
    }
  }
  .toggler-col {
    margin: 0 0 0 auto;
    padding: 0;
//     max-width: 80px;
    align-self: center;
    text-align: right;
    .navbar-toggler {
      border: none;
      padding: $grid-gutter-width*.5 $grid-gutter-width;
      font-size: $font-size-h3;
      color: $brand-secondary;
      @include media-breakpoint-up(md) {
        margin-right: $grid-gutter-width;
      }
    }
  }
  .main-navs {
    @extend .bg-secondary;
    padding: 0;
    display: flex;
    flex-direction: column;
    .search-trigger {
      color: $white;
      font-size: $font-size-xs;
    }
    .nav-ancillary {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 $grid-gutter-width*.5;
      margin-top: 10px;
      margin-bottom: 8px;
      @include media-breakpoint-up(xl) {
        padding: 0 $grid-gutter-width;
//         margin-top: 13px;
      }
      ul {
        @extend .list-normalize;
        margin-left: $grid-gutter-width*.6;
        padding-left: $grid-gutter-width*.6;
        border-left: 1px solid $brand-primary;
        @include media-breakpoint-up(xl) {
          margin-left: $grid-gutter-width*1.5;
          padding-left: $grid-gutter-width*1.5;
        }
        &.btns {
          border-left: none;
          padding-left: 0;
        }
        li {
          display: inline-block;
        }
        &.btns {
          li {
            margin: 0 $grid-gutter-width*.05;
            @include media-breakpoint-up(xl) {
              margin: 0 $grid-gutter-width*.25;
            }
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            a {
              @extend .btn;
              @extend .btn-white;
              @extend .btn-sm;
/*
              @include media-breakpoint-down(lg) {
                font-size: $font-size-sm;
              }
*/
            }
          }
        }
        &.ancillary {
          li {
            margin: 0 $grid-gutter-width*.2;
            @include media-breakpoint-up(xl) {
              margin: 0 $grid-gutter-width*.6;
            }
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            a {
              color: $white;
              font: $font-size-2xs $font-family-sans-alt;
              text-decoration: none;
              @include media-breakpoint-up(xl) {
                font-size: $font-size-2xs;
              }
              @include hover-focus {
                color: $green;
              }
            }
          }
        }
        &.social {
          li {
            margin: 0 $grid-gutter-width*.05;
            @include media-breakpoint-up(xl) {
              margin: 0 $grid-gutter-width*.25;
            }
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            a {
              display: block;
              width: $font-size-h5;
              height: $font-size-h5;
              border-radius: 50%;
              background-color: $white;
              text-align: center;
              line-height: $font-size-h5;
              @include hover-focus {
                background-color: $green
              }
              i {
                line-height: $font-size-h5;
                vertical-align: middle;
                color: $brand-secondary;
                font-size: $font-size-3xs;
              }
            }
          }
        }
      }
    }
    .nav-primary {
      @extend .bg-primary;
      margin-top: auto;
      padding: $grid-gutter-width/2 0;
      .nav {
        .nav-item {
          border-left: 1px solid $brand-secondary;
          &:first-child {
            border-left: none;
          }
          .nav-link {
            @extend .condensed-header;
            text-transform: lowercase;
            color: $white;
            padding: .75rem .5rem;
            padding-bottom: .5rem;
            @include media-breakpoint-up(lg) {
              font-size: $font-size-h5;
            }
            @include hover-focus {
              color: $green;
            }
          }
          &.active,&.current-page-ancestor {
            .nav-link {
              color: $green;
            }
          }
        }
      }
    }
  }
  #navbarResponsive {
    @extend .bg-secondary;
    padding: 0;

    #menu-mobile-navigation {
      @extend .bg-primary;
      padding: $grid-gutter-width*.25 $grid-gutter-width*1.5;
      .nav-item {
        border-top: 1px solid $brand-secondary;
        text-align: center;
        &:first-child {
          border-top: none;
        }
        .nav-link {
          @extend .condensed-header;
          font-size: $font-size-h4-lg;
          padding: $grid-gutter-width $grid-gutter-width*.4;
          line-height: 1;
          color: $white;
          @include hover-focus {
            color: $green;
          }
        }
        &.active,&.current-page-ancestor {
          .nav-link {
            color: $green;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      &.collapse {
        display: none !important;
        &.show {
          display: block !important;
        }
      }
    }
  }
}
